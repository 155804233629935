import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "./components/layout"
import { Link } from "gatsby"

export default () =>
  <Layout pageTitle="Services">
    <div className="content content--grid">
      <header>
        <StaticImage
          src="../assets/osunRiver.jpeg"
          alt="Photograph of the sacred Osun River and part of the foresty in Oshogbo, Nigeria."
          layout="constrained"
        />
      </header>
      <main className="copy">
        <h1>
          Welcome to RK Web Services!
        </h1>

        <p className="copy_services">

          <b>Need to update or migrate your site?</b> DNS and hosting support. CMS support (wix, squarespace, cargo, shopify, etc) <br /><br />
          <b>Got a brand new design you want the world to see?</b> Custom designs and layouts on any device.<br /><br />
          <b>Wanna make your page do something funky, outside the box?</b> Custom scripting and integrations.<br /><br />
          <b>Community group or collective?</b> Monthly website/tech support hours as mutual aid.<br /><br />
        </p>
        <br />
        <h2 className="title">Let's create! I want to hear your ideas!</h2><br /><br />
          <Link
            className="calendlyLink"
            to="https://calendly.com/ore-akins"
            target="_blank"
            rel="nofollow"><button className="primaryButton">
            Book a call with me</button>
          </Link>
      </main>
      <footer>
        <p>
          <i>This site is named after my ancestral homeland in Ogun state(Remo Kingdom), Nigeria.<br />*Photograph of Osun Sacred Forest River in Oshogbo, Nigeria. Source: <a href="http://willdoherty.org/wordpress/?p=1613" target="_blank"
            rel="nofollow noopener">Stardust's Shadow</a></i>
        </p>
      </footer>
    </div>
  </Layout>